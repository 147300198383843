import React from "react";
import PropTypes from "prop-types";
import { useTransition, animated } from "react-spring";

import { IoIosClose } from "react-icons/io";

const PopUp = ({ show, closeModal, children, showCloseBtn = true }) => {
  //const [show, set] = useState(false);
  const transitions = useTransition(show, null, {
    from: { opacity: 0, transform: "translate3d(0,-100px,0)" },
    enter: { opacity: 1, transform: "translate3d(0,0,0)" },
    leave: {
      opacity: 0,
      pointerEvents: "none",
      transform: "translate3d(0,100px,0)",
    },
  });
  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div
          key={key}
          className="fixed inset-0 z-50 flex items-center justify-center flex-1 font-sans leading-normal"
          style={{
            opacity: props.opacity,
            pointerEvents: props.pointerEvents,
          }}
        >
          <div
            className="absolute inset-0 z-40 opacity-75 bg-theme-900"
            //style={{ background: "rgba(54, 70, 104, 0.9)" }}
            onClick={closeModal}
          />
          <animated.div
            className="relative px-2 my-2 overflow-y-scroll"
            style={{
              flex: 1,
              transform: props.transform,
              pointerEvents: props.pointerEvents,
              zIndex: 49,
              //maxWidth: 700
            }}
          >
            <div className="absolute inset-0" onClick={closeModal} />
            <div
              className="relative modal-container"
              onClick={(e) =>
                e.target &&
                e.target.className &&
                String(e.target.className).includes("modal-container")
                  ? closeModal()
                  : null
              }
            >
              {children}
            </div>
          </animated.div>
          {showCloseBtn && (
            <div
              className="absolute top-0 right-0 z-50 p-5 text-white cursor-pointer hover:text-theme-500"
              onClick={closeModal}
            >
              <IoIosClose className="w-16 h-16 fill-current" />
            </div>
          )}
        </animated.div>
      )
  );
};

// (
//   <Transition
//     native
//     items={show}
//     from={{
//       transform: "translate3d(0,-100px,0)",
//       opacity: 0
//     }}
//     enter={{ opacity: 1, transform: "translate3d(0,0,0)" }}
//     leave={{
//       opacity: 0,
//       transform: "translate3d(0,100px,0)",
//       pointerEvents: "none"
//     }}
//     delay={1}
//   >
//     {show =>
//       show &&
//       (props => (
//         <animated.div
//           className="fixed inset-0 z-50 flex items-center justify-center flex-1"
//           style={{
//             opacity: props.opacity,
//             pointerEvents: props.pointerEvents
//           }}
//         >

//         </animated.div>
//       ))
//     }
//   </Transition>
// );

PopUp.defaultProps = {};

PopUp.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};

// const PopUpCloseBtn = styled.div`
//   font-family: ${({ theme }) => theme.fontFamily};
//   color: #fff;
//   font-weight: 600;
//   font-size: 16px;
//   position: absolute;
//   top: 0;
//   right: 0;
//   display: flex;
//   justify-content: center;
//   margin: 30px;
//   background: rgb(133, 144, 166);
//   width: 50px;
//   height: 50px;
//   border-radius: 25px;
//   cursor: pointer;
//   z-index: 151;
//   transition: opacity 0.3s ease;
//   &:hover {
//     opacity: 0.7;
//   }
// `;

const Pane = ({ title, closeModal, children }) => (
  <div
    className="w-full max-w-5xl max-h-screen mx-auto overflow-y-scroll"
    style={{ transition: "all 0.3s ease-in-out" }}
  >
    <div className="text-center text-white">
      <h2 className="text-xl">{title}</h2>
    </div>
    <div className="p-8 mx-2 bg-white rounded">{children}</div>
  </div>
);

// const PanelContainer = styled.div`
//   width: calc(100% - 20px);
//   max-width: 550px;
//   margin: 0 auto;
//   max-height: 99vh;
//   ;
// `;

export default { PopUp, Pane };
