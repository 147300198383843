import React from "react";
import { withAppContext } from "../common/withAppContext";

const NewDraftOrderModal = ({ context }) => {
  return (
    <div className="bg-white p-4 mx-auto rounded text-center max-w-lg">
      <h2 className="font-semibold text-xl mb-3">You sure you want to empty your cart?</h2>
      <div className="flex flex-col md:flex-row justify-around items-center">
        <button
          className="btn btn-primary"
          onClick={() => {
            context.emptyCart();
            context.closeModal();
          }}
        >
          <span>Yes, empty my cart</span>
        </button>
        <div className="text-xs font-semibold text-theme-700 m-2">OR</div>
        <button
          className="btn btn-secondary"
          onClick={() => {
            context.closeModal();
          }}
        >
          <span>No, take me back</span>
        </button>
      </div>
    </div>
  );
};

export default withAppContext(NewDraftOrderModal);
