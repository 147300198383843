import React, { useState, useEffect } from "react";
import matchSorter from "match-sorter";
import BaseTable, { Column, AutoResizer, SortOrder } from "react-base-table";
import "react-base-table/styles.css";

// Array.prototype.keySort = function(keys) {
//   keys = keys || {};

//   // via
//   // https://stackoverflow.com/questions/5223/length-of-javascript-object-ie-associative-array
//   var obLen = function(obj) {
//     var size = 0,
//       key;
//     for (key in obj) {
//       if (obj.hasOwnProperty(key)) size++;
//     }
//     return size;
//   };

//   // avoiding using Object.keys because I guess did it have IE8 issues?
//   // else var obIx = function(obj, ix){ return Object.keys(obj)[ix]; } or
//   // whatever
//   var obIx = function(obj, ix) {
//     var size = 0,
//       key;
//     for (key in obj) {
//       if (obj.hasOwnProperty(key)) {
//         if (size == ix) return key;
//         size++;
//       }
//     }
//     return false;
//   };

//   var keySort = function(a, b, d) {
//     d = d !== null ? d : 1;
//     // a = a.toLowerCase(); // this breaks numbers
//     // b = b.toLowerCase();
//     if (a == b) return 0;
//     return a > b ? 1 * d : -1 * d;
//   };

//   var KL = obLen(keys);

//   if (!KL) return this.sort(keySort);

//   for (var k in keys) {
//     // asc unless desc or skip
//     keys[k] =
//       keys[k] == "desc" || keys[k] == -1
//         ? -1
//         : keys[k] == "skip" || keys[k] === 0
//         ? 0
//         : 1;
//   }

//   this.sort(function(a, b) {
//     var sorted = 0,
//       ix = 0;

//     while (sorted === 0 && ix < KL) {
//       var k = obIx(keys, ix);
//       if (k) {
//         var dir = keys[k];
//         sorted = keySort(a[k], b[k], dir);
//         ix++;
//       }
//     }
//     return sorted;
//   });
//   return this;
// };


/** NOTE:
 * Base Table doesn't actually initially sort the order. Do this before passing in the infomation.
 */

const DataGrid = props => {
  const [data, setData] = useState(Array.isArray(props.data) ? props.data : []);
  const [filterTerm, setFilterTerm] = useState("");
  const [sortState, setSortState] = useState(props.defaultSort ? props.defaultSort : {});
  useEffect(() => {
    if(Array.isArray(props.data)){
      setData(props.data);
    }

    // setTimeout(() => {
    //   onColumnSort({ key: 'orderDate', order: SortOrder.DESC }); //todo this cause a problem (maybe?)
    // }, 500)
    
    

  }, [props.data]);
  
  const filterData = newFilterTerm => {
    setFilterTerm(newFilterTerm);
    let filteredData = props.data;
    if (props.filterKeys && newFilterTerm) {
      filteredData = matchSorter(filteredData, newFilterTerm, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: props.filterKeys
      });
    }
    setData(filteredData);
  };
  const onColumnSort = ({ key = false, order = false }) => {
    console.log('On Column Sort Data, ', data.length, {key}, {order});
    if(key){
      const newSortState = {
        ...sortState,
        [key]: sortState[key] === SortOrder.DESC ? null : order
      };
      setSortState(newSortState);
      //setData(data.reverse());
      //const sortedData = data.keySort({docNum: "desc"});
      //console.log({newSortState}, {sortedData});
      //setData(sortedData);
    }
  };
  return (
    <div className="">
      {props.filterKeys && props.filterKeys.length > 0 && (
        <div className="flex flex-wrap mb-3">
          <label className="flex-grow w-auto">
            <span className="hidden text-theme-700">Filter</span>
            <input
              name="title"
              type="text"
              placeholder="Filter"
              maxLength="30"
              value={filterTerm}
              onChange={e => filterData(e.target.value)}
              className="block w-full px-0 py-2 mt-1 text-sm border-b bg-theme-100 border-theme-500"
            />
          </label>
        </div>
      )}
      <AutoResizer height={150}>
        {({ width, height }) => (
          <BaseTable
            {...props}
            className="overflow-hidden rounded font-base"
            style={{
              boxShadow: "none",
              fontSize: 14,
              minHeight: Array.isArray(data) && data.length > 0 ? 0 : 150
            }}
            width={width}
            //height={data.length > 0 ? false : 150}
            maxHeight={
              Array.isArray(data) && data.length > 0 ? (props.height ? props.height : 550) : 150
            }
            data={data}
            sortState={sortState}
            //sortBy={sortState}
            onColumnSort={onColumnSort}
            emptyRenderer={
              <div className="flex items-center justify-center h-full text-base">
                No entries
              </div>
            }
            //fixed={width > 640 ? props.fixed : false}
          />
        )}
      </AutoResizer>
    </div>
  );
};

export default DataGrid;
export { Column, SortOrder };
