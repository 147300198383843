const buildGatsbyImage = (imageArr) => {
  imageArr.sort((a, b) => a.size - b.size);
  const base64Image = imageArr.find((image) => image.base64);
  const middleImage = imageArr[Math.floor(imageArr.length / 2)];
  //const largestImage = imageArr.reduce((prev, current) => (prev.size > current.size) ? prev : current);
  let setString = "";
  imageArr.forEach((image) => {
    if (!image.base64) {
      setString += `${image.url} ${image.size}w,`;
    }
  });
  const gatsbyImage = {
    gatsbyImageData: {
      layout: "constrained",
      images: {
        src: middleImage.url,
        srcSet: setString,
        sizes: "(max-width: 526px) 100vw, 526px",
        fallback: {
          src: base64Image.url,
          srcSet: setString,
          sizes: "(max-width: 526px) 100vw, 526px",
        },
      },
      placeholder: {
        fallback: base64Image.base64,
      },
    },
  };
  return gatsbyImage;
};

// const image = {
//   childImageSharp: {
//     fluid: {
//       base64:
//         "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAQFAgMG/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAbal6GHE8VjpIkCv/8QAHBABAAEFAQEAAAAAAAAAAAAAAgABBBIUIQMR/9oACAEBAAEFArn7r2WexF0+IJcXaAY1n//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABwQAAICAgMAAAAAAAAAAAAAAAERECEAMQIScf/aAAgBAQAGPwLn12sFkhXcHKTnZPsf/8QAHBABAAEEAwAAAAAAAAAAAAAAAREAECFBMVGB/9oACAEBAAE/IS8rRFTSzHZYgnhKXpwaGwlJSeqyPuTb/9oADAMBAAIAAwAAABAcB4D/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAdEAEBAAICAwEAAAAAAAAAAAABEQAQITFBUWGR/9oACAEBAAE/EAru+SP7ladQMhkKXUx6BvrD1eGAQv1fJqrwJVEwuxjqLX//2Q==",
//       aspectRatio: 1,
//       src: "/static/e0568dcb407d829b2d3298a3cc4e5383/9116e/BAC10.jpg",
//       srcSet:
//         "/static/e0568dcb407d829b2d3298a3cc4e5383/88033/BAC10.jpg 132w,/static/e0568dcb407d829b2d3298a3cc4e5383/dd070/BAC10.jpg 263w,/static/e0568dcb407d829b2d3298a3cc4e5383/9116e/BAC10.jpg 526w,/static/e0568dcb407d829b2d3298a3cc4e5383/6e4fb/BAC10.jpg 789w,/static/e0568dcb407d829b2d3298a3cc4e5383/0c9d2/BAC10.jpg 1052w,/static/e0568dcb407d829b2d3298a3cc4e5383/f963e/BAC10.jpg 1578w,/static/e0568dcb407d829b2d3298a3cc4e5383/10eb4/BAC10.jpg 1800w",
//       sizes: "(max-width: 526px) 100vw, 526px"
//     }
//   }
// };

export { buildGatsbyImage };
