import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { db, timestamp } from "../../services/firebaseService";
import { withAppContext } from "../common/withAppContext";
import DataGrid, { Column } from "../DataGrid";

const BackOrderModal = (props) => {
  const { context } = props;

  const [step, setStep] = useState("addon-orders");

  const [addOnOrders, setAddOnOrders] = useState([]);

  const [backOrderItems, setBackOrderItems] = useState([]);

  const [isLoading, setLoading] = useState(true);

  const [isSubmitting, setSubmitting] = useState(false);

  const [selectedOrder, setSelectedOrder] = useState(null);

  const [displayModal, setDisplayModal] = useState(false)
  /* 
    Addon orders are not working because of a bug with SAP API.
    The goal is to deactivate addon orders while reimplementing back orders.
    First step is to comment everything related to  add on 
  */
  useEffect(() => {
    console.log("----- starting back order")
    //if(isLoading !== null || backOrderItems.length > 0) return

    //setLoading(true);

    const unsubscribe = db
      .collection(
        process.env.REACT_APP_ORDERS_COLLECTION
          ? process.env.REACT_APP_ORDERS_COLLECTION
          : "orders"
      )
      //.orderBy("addedAt")
      .where("docstatus", "==", "O")
      .where("CardCode", "==", context.appStore.account.code)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          console.log("cardCode", context.appStore.account.code)
          let orders = [];
          const backOrders = [];
          // const addOnOrders = []; //Commented to skip addon

          snapshot.forEach((doc) =>
            orders.push({ ...doc.data(), uid: doc.id })
          );

          orders.forEach((order) => {
            const closedItems = [];
            Object.entries(order.items).forEach(
              ([key, item]) => item.LineStatus === "C" && closedItems.push(item)
            );
            const openItems = [];
            Object.entries(order.items).forEach(
              ([key, item]) => item.LineStatus === "O" && openItems.push(item)
            );

            // if (closedItems.length === 0 && openItems.length > 0) //Commented to skip addon
            //   addOnOrders.push({ ...order, items: openItems });

            if (closedItems.length > 0 && openItems.length > 0)
              backOrders.push({ ...order, items: openItems });
          });

          // Back Orders
          const backOrderList = [];
          backOrders.forEach((backOrder) => {
            backOrder.items.forEach((item) => {
              backOrderList.push({
                uniqueID: `${backOrder.DocNum}-${item.ItemCode}`,
                DocNum: backOrder.DocNum,
                ...item,
              });
            });
          });

          console.log({ addOnOrders }, { backOrderList });

          if (!addOnOrders.length && !backOrderList.length) {
            context.closeModal()
          } else {
            setDisplayModal(true)
          }

          if (addOnOrders.length === 0) {
            setStep("back-order-items");
          }

          // Add On Orders
          // setAddOnOrders(addOnOrders);

          // Add Product Status
          backOrderList.forEach((item, idx) => {
            const product = context.lookupProduct(item.ItemCode);
            if (product) {
              item.Available = product.Available;
              item.Status = product.Status;
            }
          });

          setBackOrderItems(backOrderList);

          setLoading(false);
        } else {
          context.closeModal();
        }
      });
      console.log("display", displayModal)
    return unsubscribe;
  }, []);

  const handleAddOnOrdersSubmit = async (e) => {
    console.log("!!! ADDON ORDER SUBMIT")
    e.preventDefault();

    const addOnOrder = addOnOrders.find(
      (order) => order.DocNum === selectedOrder
    );

    if (addOnOrder) {
      const existingOrders =
        (context.state.currentOrder && context.state.currentOrder.items) || [];

      context.appStore.updateCurrentOrderId(addOnOrder.uid);

      await db
        .collection(
          process.env.REACT_APP_ORDERS_COLLECTION
            ? process.env.REACT_APP_ORDERS_COLLECTION
            : "orders"
        )
        .doc(addOnOrder.uid)
        .update({
          status: "edit",
          changeLog: [],
          updatedAt: timestamp.now(),
        });

      for (const item of existingOrders) {
        await context.addOrderItem(item.code, item.qty);
      }
    }

    if (backOrderItems.length > 0) {
      setStep("back-order-items");
    } else {
      context.closeModal();
    }

    return false;
  };

  const handleBackOrdersItemsSubmit = async (e) => {
    e.preventDefault();

    const changeLog = [];
    const newItems = [];
    const cancelledItems = [];

    for (const item of backOrderItems) {
      if (item.action === "Add") {
        const existingItem = newItems.find(
          (existing) => existing.ItemCode === item.ItemCode
        );
        if (existingItem) {
          existingItem.qty = Number(existingItem.qty) + Number(item.qty);
        } else {
          console.log("new", item);
          newItems.push(item);
        }

        changeLog.push({
          ItemCode: item.ItemCode,
          DocNum: item.DocNum,
          DocEntry: item.DocEntry,
          LineNum: item.LineNum,
          action: "ADD",
          updatedAt: timestamp.now(),
        });
      }
      if (item.action === "Cancel") {
        cancelledItems.push(item);
        changeLog.push({
          ItemCode: item.ItemCode,
          DocNum: item.DocNum,
          DocEntry: item.DocEntry,
          LineNum: item.LineNum,
          action: "CANCEL",
          updatedAt: timestamp.now(),
        });
      }
    }

    for (const item of newItems) {
      console.log(item);
      await context.addOrderItem(item.ItemCode, item.qty, {
        prevDocNum: item.DocNum,
      });
    }

    // Add changeLog

    await db
      .collection(
        process.env.REACT_APP_ORDERS_COLLECTION
          ? process.env.REACT_APP_ORDERS_COLLECTION
          : "orders"
      )
      .doc(context.appStore.currentOrderId)
      .update({
        changeLog,
        updatedAt: timestamp.now(),
      });

    context.closeModal();

    return false;
  };

  const handleBackOrderItemAddSelectionToggle = ({ target }) => {
    // setSelectedBackOrderAddItems((s) => ({ ...s, [target.name]: !s[target.name] }));
    const itemId = target.name;

    setBackOrderItems(
      backOrderItems.map((item, idx) => {
        if (`${item.DocNum}-${item.ItemCode}` !== itemId) {
          return item;
        }
        if (item.action === "Add") {
          delete item.action;
          return {
            ...item,
          };
        } else {
          return {
            ...item,
            action: "Add",
          };
        }
      })
    );
  };

  const handleBackOrderItemCancelSelectionToggle = ({ target }) => {
    // setSelectedBackOrderCancelItems((s) => ({ ...s, [target.name]: !s[target.name] }));

    const itemId = target.name;

    setBackOrderItems(
      backOrderItems.map((item, idx) => {
        if (`${item.DocNum}-${item.ItemCode}` !== itemId) {
          return item;
        }
        if (item.action === "Cancel") {
          delete item.action;
          return {
            ...item,
          };
        } else {
          return {
            ...item,
            action: "Cancel",
          };
        }
      })
    );
  };

  const handleSelectedOpenOrder = (docNum) => {
    setSelectedOrder(docNum === selectedOrder ? null : docNum);
  };

  if ((context.state && !context.state.currentOrder && isLoading) || isLoading) {
    return (
      <div className="flex flex-col items-center justify-center max-w-md p-6 mx-auto bg-white rounded">
        <div className="w-12 h-12 mx-auto mb-6 app-spinner" />
        <span className="text-xl">Checking for back orders...</span>
      </div>
    );
  }

  else if (displayModal) {
    if (step === "back-order-items") {
      return (
        <div
          className="max-w-6xl p-4 mx-auto bg-white rounded lg:p-6"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <form className="w-full" onSubmit={handleBackOrdersItemsSubmit}>
            <h1 className="mb-5 font-serif text-2xl lg:text-2xl">Back Orders</h1>
            <p className="mb-5">
              We have backorders from your previous orders that are ready to ship!
              How would you like us to handle these?{" "}
            </p>
  
            <p className="mb-2">
              Check <strong className="font-medium underline">ADD</strong> to
              include backordered items on this order.
            </p>
            <p className="mb-2">
              Check <strong className="font-medium underline">CANCEL</strong> to
              cancel the backordered item.
            </p>
            <p className="mb-5">
              For items that are out of stock, check{" "}
              <strong className="font-medium underline">CANCEL</strong> if you no
              longer require this item; otherwise, leave it as-is and well add it
              to your next order.{" "}
            </p>
            <p className="mb-5">
              To change your order quantity, add the backordered item to your
              order and adjust the quantity from your order cart.{" "}
            </p>
  
            <DataGrid
              data={backOrderItems}
              height={650}
              rowKey="uniqueID"
              key={JSON.stringify(backOrderItems.map((item) => item.action))}
              fixed
              columns={[
                {
                  key: "salesOrder",
                  dataKey: "salesOrder",
                  title: "Sales Order #",
                  width: 150,
                  className: "p-2",
                  cellRenderer: ({ rowData, ...rest }) => (
                    <span className="text-lg font-medium">{rowData.DocNum}</span>
                  ),
                },
                {
                  key: "item",
                  dataKey: "item",
                  title: "Item #",
                  width: 100,
                  cellRenderer: ({ rowData, ...rest }) => rowData.ItemCode,
                },
                {
                  key: "itemName",
                  dataKey: "itemName",
                  title: "Item Name",
                  width: 300,
                  cellRenderer: ({ rowData, ...rest }) => rowData.Dscription,
                },
                {
                  key: "qty",
                  dataKey: "qty",
                  title: "Quantity",
                  width: 100,
                  className: "p-2",
                  cellRenderer: ({ rowData, ...rest }) => rowData.OpenQty,
                },
                {
                  key: "itemCost",
                  dataKey: "itemCost",
                  title: "Item Cost",
                  width: 100,
                  className: "p-2",
                  cellRenderer: ({ rowData, ...rest }) =>
                    `$ ${rowData.Price.toFixed(2)}`,
                },
                {
                  key: "lineTotal",
                  dataKey: "lineTotal",
                  title: "Line Total",
                  width: 100,
                  className: "p-2",
                  cellRenderer: ({ rowData, ...rest }) =>
                    `$ ${rowData.LineTotal.toFixed(2)}`,
                },
                {
                  key: "status",
                  dataKey: "status",
                  title: "Status",
                  width: 200,
                  className: "p-2",
                  cellRenderer: ({ rowData, ...rest }) => rowData.Status, // TODO - pull from Product.Status
                },
                // {
                //   key: "action",
                //   dataKey: "action",
                //   title: "Action",
                //   width: 200,
                //   className: "p-2",
                //   cellRenderer: ({ rowData, ...rest }) => rowData.action,
                // },
                {
                  key: "add",
                  dataKey: "add",
                  title: "Add",
                  frozen: "right",
                  width: 50,
                  cellRenderer: ({ rowData, ...rest }) => (
                    //rowData.status === "In Stock" && (
                    <label className="flex items-center w-full">
                      <input
                        name={`${rowData.DocNum}-${rowData.ItemCode}`}
                        type="checkbox"
                        onChange={handleBackOrderItemAddSelectionToggle}
                        className="w-6 h-6 form-checkbox"
                        checked={rowData.action && rowData.action === "Add"}
                      />
                    </label>
                  ),
                },
                {
                  key: "cancel",
                  dataKey: "canel",
                  title: "Cancel",
                  frozen: "right",
                  width: 75,
                  cellRenderer: ({ rowData, ...rest }) => (
                    <label className="flex items-center justify-center w-full">
                      <input
                        name={`${rowData.DocNum}-${rowData.ItemCode}`}
                        type="checkbox"
                        onChange={handleBackOrderItemCancelSelectionToggle}
                        className="w-6 h-6 form-checkbox"
                        checked={rowData.action && rowData.action === "Cancel"}
                      />
                    </label>
                  ),
                },
              ]}
            />
            <div className="flex mt-3 buttons">
              {addOnOrders.length > 0 && (
                <button
                  className="ml-auto mr-4 btn btn-lg"
                  onClick={() => setStep("addon-orders")}
                >
                  Back
                </button>
              )}
              <a
                className="ml-auto mr-4 btn btn-lg"
                onClick={() => {
                  context.closeModal();
                  return false;
                }}
              >
                Skip
              </a>
              <button className="btn btn-primary btn-lg" type="submit">
                NEXT
              </button>
            </div>
          </form>
        </div>
      );
    }
  
    return (
      <div
        className="max-w-4xl p-4 mx-auto bg-white rounded lg:p-6"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <form className="w-full" onSubmit={handleAddOnOrdersSubmit}>
          <h1 className="mb-5 font-serif text-2xl lg:text-2xl">
            Want to add on an existing order?
          </h1>
  
          <p className="mb-5">
            We have the following orders pending in our system for you. Would you
            like to add this order to your existing order, or would you like us to
            ship this order separately?
          </p>
          <p className="mb-5">
            Check to add-on, leave blank to create as new order.{" "}
          </p>
          {/* <pre>{JSON.stringify(backOrderItems, 0, 2)}</pre> */}
          <DataGrid
            data={addOnOrders}
            height={650}
            rowKey="uid"
            key={selectedOrder}
            fixed
            columns={[
              {
                key: "salesOrder",
                dataKey: "salesOrder",
                title: "Sales Order #",
                width: 150,
                className: "p-2",
                cellRenderer: ({ rowData, ...rest }) => (
                  <span className="text-lg font-medium">{rowData.DocNum}</span>
                ),
              },
              {
                key: "customerPO",
                dataKey: "customerPO",
                title: "Customer PO#",
                width: 150,
                className: "p-2",
                cellRenderer: ({ rowData, ...rest }) => (
                  <span className="">
                    {rowData.NumAtCard ? rowData.NumAtCard : " - "}
                  </span>
                ),
              },
              {
                key: "orderDate",
                dataKey: "orderDate",
                title: "Order Date",
                width: 100,
                cellRenderer: ({ rowData, ...rest }) => {
                  if (rowData.DocDate) {
                    const dateToDisplay = rowData.DocDate.seconds ? rowData.DocDate.toDate() : rowData.DocDate;
                    return dayjs(dateToDisplay).format("YYYY/MM/DD");
                  } else {
                    return " - ";
                  }
                }   
              },
              {
                key: "requestedShipDate",
                dataKey: "requestedShipDate",
                title: "Requested Ship Date", 
                width: 200,
                cellRenderer: ({ rowData, ...rest }) => {
                  if (rowData.DocDueDate) {
                    const dateToDisplay = rowData.DocDueDate.seconds ? rowData.DocDueDate.toDate() : rowData.DocDueDate;
                    return dayjs(dateToDisplay).format("YYYY/MM/DD");
                  } else {
                    return " - ";
                  }
                }
              },
              {
                key: "value",
                dataKey: "value",
                title: "Value",
                width: 100,
                className: "p-2",
  
                frozen: Column.FrozenDirection.RIGHT,
                cellRenderer: ({ rowData, ...rest }) =>
                  `$ ${rowData.DocTotal.toFixed(2)}`,
              },
              {
                key: "actions",
                dataKey: "actions",
                title: "Add to Order",
                frozen: "right",
                // align: Column.Alignment.RIGHT,
                width: 150,
                headerRenderer: ({ rowData, ...rest }) => (
                  <label className="flex items-center justify-center w-full">
                    Add to Order
                  </label>
                ),
                cellRenderer: ({ rowData, ...rest }) => (
                  <label className="flex items-center justify-center w-full">
                    <input
                      name={rowData.DocNum}
                      type="checkbox"
                      onChange={(e) => handleSelectedOpenOrder(rowData.DocNum)}
                      className="w-6 h-6 border-gray-900 form-checkbox"
                      checked={selectedOrder === rowData.DocNum}
                    />
                    <span className="ml-2 text-lg">Add</span>
                  </label>
                ),
              },
            ]}
          />
  
          <div className="flex mt-3 buttons">
            <a
              className="ml-auto mr-4 btn btn-lg"
              onClick={() => {
                // context.closeModal();
                setStep("back-order-items");
                return false;
              }}
            >
              Skip
            </a>
            <button
              className={`btn btn-primary btn-lg ${
                isSubmitting ? "spinner" : ""
              }`}
              type="submit"
              disabled={isSubmitting}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    );
  }
};

export default withAppContext(BackOrderModal);
