import React, { Component } from "react";
import { observer } from "mobx-react";
import { navigate } from "gatsby";
import dayjs from "dayjs";
import * as Sentry from "@sentry/browser";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  setup,
  auth,
  getFirebase,
  checkLoginStatus,
  db,
  timestamp,
} from "../../services/firebaseService";
import { getMyListings } from "../../services/ProductsService";
import config from "../../services/ConfigService";

import Modal from "../Modal";

// import NewDraftOrderModal from "../modals/NewDraftOrderModal";
import EmptyCartModal from "../modals/EmptyCartModal";
import ProductModal from "../modals/ProductModal";
import EmailDraftOrderModal from "../modals/EmailDraftOrderModal";
import BackOrderModal from "../modals/BackOrderModal";
import DeletedDraftProductsModal from "../modals/DeletedDraftProductsModal";

// Create a new context for the app
export const AppContext = React.createContext("app");

class AppProvider extends Component {
  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    currentUser: false,
    currentOrderId: null,
    currentOrder: null,
    products: [],
    showModal: false,
    modalComponent: null,
  };

  async componentDidMount() {
    /* Tried moving this to AppStore, but it's aa hassel. Keep it here */

    console.log(
      `1/3: 🚀 APP MOUNTED, ENV: ${process.env.NODE_ENV}, GATSBY ENV: ${process.env.GATSBY_ACTIVE_ENV}`
    );
    //SetUp Firebase
    setup();
    console.log("2/3: ⚙️ FIREBASE SETUP");

    try {
      let user = await checkLoginStatus();

      if (user && user.email) {
        // Get Access Token
        const accessToken = await user.getIdToken(false);
        console.log(
          "3/3: 👤 USER LOGGED IN",
          user.displayName,
          "ACCESS TOKEN",
          accessToken.substring(0, 10)
        );

        this.props.appStore.setupCurrentUser({
          displayName: user.displayName ? user.displayName : "N/A",
          email: user.email,
          emailVerified: user.emailVerified,
          accessToken,
        });

        this.setState({ isAuthenticated: true, currentUser: user });
        if (this.props.appStore?.account?.code) {
          await getMyListings(this.props.appStore.account.code);
        }

        // Set Sentry
        Sentry.setUser({ email: user.email });

        this.checkLogoutOrDataRefresh();
        // this.checkDataRefresh();

        // Session check
        this.periodicallyUpdateSession();
      }
    } catch (error) {
      if (error.toString() === "not-logged-in") {
        console.log("3/3: 👤 USER ISN'T LOGGED IN");
        this.props.appStore.wipeCurrentUser();
      } else {
        console.log("Error", { error });
      }
    }

    this.setState({ isAuthenticating: false });

    try {
      this.googleProvider = new getFirebase.auth.GoogleAuthProvider();
      this.facebookProvider = new getFirebase.auth.FacebookAuthProvider();
      this.twitterProvider = new getFirebase.auth.TwitterAuthProvider();
      this.microsoftProvider = new getFirebase.auth.OAuthProvider(
        "microsoft.com"
      );
    } catch (err) {
      console.error(`Couldn't set up Auth Providers`, err);
    }

    /* CART */

    if (
      this.props.appStore.currentOrderId &&
      this.props.appStore.currentOrderId !== null
    ) {
      this.onListenForActiveOrder();
    }

    /* SETTINGS */
    db.collection("settings")
      .doc("general")
      .onSnapshot((doc) => {
        if (doc) {
          const settings = doc.data();
          Object.entries(settings).forEach(([setting, val]) => {
            if (setting !== "updatedAt") {
              this.props.appStore.setSetting(setting, val);
            }
          });
          // console.log(doc.data());
        } else {
        }
      });

    // this.handleShowBackOrderModal()
  }

  checkLogoutOrDataRefresh = async () => {
    console.log("Check logout or data refresh");
    // Logout user after 5 days of inactivity
    const logoutLimitDate = new Date();
    logoutLimitDate.setDate(logoutLimitDate.getDate() - 5);

    const lastActivityTimestamp = localStorage.getItem("lastActivityDate");
    const lastActivityDate = new Date(parseInt(lastActivityTimestamp));

    localStorage.setItem("lastActivityDate", Date.now());

    if (lastActivityTimestamp && lastActivityDate < logoutLimitDate) {
      console.log("[AppProvider] - Logout after 5 days");
      alert("Your account has been logged out due to inactivity");
      return await this.handleSignOut();
    } else {
      // If user is not logged out, then check if refreshing its data is needed
      this.checkDataRefresh();
    }
  };

  checkDataRefresh = async () => {
    console.log("Check data refresh");

    // refresh user's data every 24 hours
    const dataRefreshLimitDate = new Date();
    dataRefreshLimitDate.setHours(dataRefreshLimitDate.getHours() - 24);

    const lastDataRefreshTimestamp = localStorage.getItem(
      "lastDataRefreshDate"
    );
    const lastDataRefreshDate = new Date(parseInt(lastDataRefreshTimestamp));

    if (!lastDataRefreshTimestamp) {
      localStorage.setItem("lastDataRefreshDate", Date.now());
    }
    if (
      this.props.appStore.currentAccount &&
      lastDataRefreshTimestamp &&
      lastDataRefreshDate < dataRefreshLimitDate
    ) {
      console.log("[AppProvider] - Refresh user data after 24 hours");
      localStorage.setItem("lastDataRefreshDate", Date.now());
      this.props.appStore.updateCurrentAccount(
        this.props.appStore.currentAccount.id
      );
    }
  };

  periodicallyUpdateSession = async () => {
    try {
      let user = await checkLoginStatus();

      // Checks every 30 minutes
      setInterval(async () => {
        const accessToken = await user.getIdToken(false);
        console.log("Access Token, ", accessToken.substring(0, 10));
        this.props.appStore.currentUser.updateAccessToken(accessToken);
      }, 1000 * 60 * 30);
    } catch (error) {
      console.log(`Couldn't request new access token, signing out...`);
      await auth.signOut();
    }
  };

  sayHi = () => {
    alert("Hi!");
  };

  /**
   * MODAL
   */
  handleShowModal = (component) => {
    this.setState({ showModal: true, modalComponent: component });
  };
  handleShowModalPane = (component, title) => {
    this.setState({
      showModal: true,
      modalComponent: <Modal.Pane title={title}>{component}</Modal.Pane>,
    });
  };
  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  /**
   * AUTH
   */

  gotoSignIn = async () => {
    navigate(`/app/signin`);
    return null;
  };

  handleSignOut = async () => {
    console.log("👤 SIGNING OUT...");
    localStorage.removeItem("lastActivityDate");
    localStorage.removeItem("lastDataRefreshDate");

    // Hide cart
    this.props.appStore.hideCart();
    //Wipe cart
    this.props.appStore.updateCurrentOrderId(null);

    await auth.signOut();

    this.setState({ currentOrderId: null, currentOrder: null });
    window.location.reload(true);
  };

  handleSignInWithGoogle = async () => {
    try {
      await auth.signInWithPopup(this.googleProvider);
      localStorage.setItem("lastDataRefreshDate", Date.now());
      window.location.reload(true);
    } catch (error) {
      console.log(error);
    }
  };

  handleSignInWithMicrosoft = async () => {
    try {
      await auth.signInWithPopup(this.microsoftProvider);
      localStorage.setItem("lastDataRefreshDate", Date.now());
      window.location.reload(true);
    } catch (error) {
      console.log(error);
      if (error.code === "auth/invalid-credential") {
        return alert("Your email address isn't setup to use this method.");
      }
      if (error.code === "auth/account-exists-with-different-credential") {
        alert(`An account with an E-Mail address to
        this account already exists. Try to login from
        this account instead and associate your social accounts on
        your personal account page.`);

        // auth.fetchProvidersForEmail(error.email)
        // .then(providers => {
        //   //providers returns this array -> ["google.com"]
        //   // You need to sign in the user to that google account
        //   // with the same email.
        //   // In a browser you can call:
        //   // var provider = new firebase.auth.GoogleAuthProvider();
        //   // provider.setCustomParameters({login_hint: error.email});
        //   // firebase.auth().signInWithPopup(provider)
        //   // If you have your own mechanism to get that token, you get it
        //   // for that Google email user and sign in
        //   auth.signInWithCredential(googleCred)
        //     .then(user => {
        //       // You can now link the pending credential from the first
        //       // error.
        //       user.linkWithCredential(error.credential)
        //     })
        //     .catch(error => log(error))
      }
    }
  };

  /**
   * PRODUCTS
   */

  handleShowProductModal = (productCode) => {
    return this.handleShowModal(<ProductModal productCode={productCode} />);
  };

  handleSetProducts = (products) => {
    if (this.state.products.length === 0) {
      this.setState({ products });
    }
  };

  /**
   * CART
   */

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentOrderId !== this.props.appStore.currentOrderId) {
      if (this.unsubscribeToActiveOrder instanceof Function) {
        console.log("Cart changed - previous : ", prevState.currentOrderId);
        console.log(
          "Cart changed - current : ",
          this.props.appStore.currentOrderId
        );
        this.unsubscribeToActiveOrder();
      }
      this.onListenForActiveOrder();
    }

    // Check Doc Status
    if (
      prevState.currentOrder &&
      this.state.currentOrder &&
      prevState.currentOrder.docstatus === "O" &&
      this.state.currentOrder.docstatus === "C"
    ) {
      this.props.appStore.updateCurrentOrderId(null);
      return this.showOrderFulfilledModal();
    }

    //If Cancelled

    if (
      this.state.currentOrder &&
      prevState.currentOrder &&
      prevState.currentOrder.docstatus === "O" &&
      this.state.currentOrder.docstatus === "C" &&
      this.state.currentOrder.Canceled === "Y"
    ) {
      this.props.appStore.updateCurrentOrderId(null);
      return this.showOrderCancelledModal();
    }

    // Check Line Items

    const updatedLineItems = [];
    if (
      this.state.currentOrder &&
      prevState.currentOrder &&
      this.state.currentOrder.items &&
      prevState.currentOrder.items
    ) {
      console.log(`Current Items: ${this.state.currentOrder.items.length}`);
      console.log(`Prev Items: ${prevState.currentOrder.items.length}`);

      this.state.currentOrder.items.forEach((item) => {
        const existingItem = prevState.currentOrder.items.find(
          (prevItem) => prevItem.ItemCode === item.ItemCode
        );
        if (
          existingItem &&
          existingItem.LineStatus === "O" &&
          item.LineStatus === "C"
        ) {
          const product = this.handleLookupProduct(item.ItemCode);

          updatedLineItems.push({
            ...item,
            gatsbyImage:
              product && product.gatsbyImage ? product.gatsbyImage : null,
          });
        }
      });

      console.log({ updatedLineItems });

      if (updatedLineItems.length > 0) {
        return this.handleShowModal(
          <div className="max-w-lg px-4 py-6 mx-auto text-center bg-white rounded">
            <h2 className="mt-3 mb-5 text-lg text-gray-700 ">
              Your order has been adjusted
            </h2>
            <div className="flex flex-col mb-5">
              {updatedLineItems.map((item) => (
                <div
                  key={item.ItemCode}
                  className="flex items-center mb-2 overflow-hidden rounded bg-theme-300"
                >
                  {item.gatsbyImage && (
                    <GatsbyImage
                      image={item.gatsbyImage.gatsbyImageData}
                      objectFit="cover"
                      objectPosition="50% 50%"
                      alt={item.code}
                      className="w-16 h-16"
                      imgStyle={{
                        objectFit: "cover",
                        backgroundColor: "#fff",
                      }}
                    />
                  )}
                  <div className="flex-row flex-grow px-3 text-left">
                    Item <strong>{item.ItemCode}</strong> was closed.
                  </div>
                </div>
              ))}
            </div>
            <div className="flex flex-col items-center justify-around md:flex-row">
              <button
                className="w-32 btn btn-secondary"
                onClick={() => {
                  this.handleCloseModal();
                }}
              >
                <span>OK</span>
              </button>
            </div>
          </div>
        );
      }
    }
  }

  showOrderFulfilledModal = () => {
    return this.handleShowModal(
      <div className="max-w-lg px-4 py-6 mx-auto text-center bg-white rounded">
        <h2 className="mt-3 mb-5 text-lg text-gray-700 ">
          Your order has been fulfilled and can no longer be edited
        </h2>
        <div className="flex flex-col items-center justify-around md:flex-row">
          <button
            className="w-32 btn btn-secondary"
            onClick={() => {
              this.handleCloseModal();
            }}
          >
            <span>OK</span>
          </button>
        </div>
      </div>
    );
  };

  showOrderCancelledModal = () => {
    return this.handleShowModal(
      <div className="max-w-lg px-4 py-6 mx-auto text-center bg-white rounded">
        <h2 className="mt-3 mb-5 text-lg text-gray-700 ">
          Your order has been cancelled and can not longer be edited
        </h2>
        <div className="flex flex-col items-center justify-around md:flex-row">
          <button
            className="w-32 btn btn-secondary"
            onClick={() => {
              this.handleCloseModal();
            }}
          >
            <span>OK</span>
          </button>
        </div>
      </div>
    );
  };

  componentWillUnmount() {
    if (this.unsubscribeToActiveOrder instanceof Function) {
      this.unsubscribeToActiveOrder();
    }
  }

  onListenForActiveOrder = () => {
    let shouldVerifyItems = true;

    if (
      this.state.currentOrderId !== null &&
      this.state.currentOrder !== null &&
      this.state.currentOrder.items.length === 0 &&
      this.state.currentOrderId !== this.props.appStore.currentOrderId
    ) {
      db.collection(
        process.env.REACT_APP_ORDERS_COLLECTION
          ? process.env.REACT_APP_ORDERS_COLLECTION
          : "orders"
      )
        .doc(this.state.currentOrderId)
        .delete();
    }

    this.setState({
      loading: true,
      currentOrderId: this.props.appStore.currentOrderId,
      currentOrder: null,
    });

    if (!this.props.appStore.currentOrderId) {
      return;
    }

    this.unsubscribeToActiveOrder = db
      .collection(
        process.env.REACT_APP_ORDERS_COLLECTION
          ? process.env.REACT_APP_ORDERS_COLLECTION
          : "orders"
      )
      .doc(this.props.appStore.currentOrderId)
      .onSnapshot((doc) => {
        if (doc && doc.data()) {
          //Check to see if subscribed order has either been fulfilled or cancelled
          if (doc.data().docstatus === "C") {
            this.props.appStore.updateCurrentOrderId(null);
            return this.showOrderFulfilledModal();
          }

          if (doc.data().docstatus === "C" && doc.data().Canceled === "Y") {
            this.props.appStore.updateCurrentOrderId(null);
            return this.showOrderCancelledModal();
          }

          const totalPrice =
            doc.data().items.length > 0
              ? doc.data().items.reduce((sum, e) => sum + e.price * e.qty, 0)
              : 0;

          // await
          if (shouldVerifyItems) {
            shouldVerifyItems = false;
            console.log("Start verifying items availability");
            this.verifyItemsAvailibility(doc);
          }

          const isPreOrder =
            doc.data().items.length > 0 &&
            doc.data().items.filter((e) => e.xmasItem || e.springItem).length >
              0
              ? true
              : false;
          this.setState({
            currentOrder: {
              id: doc.id,
              ...doc.data(),
              totalPrice: parseFloat(totalPrice).toFixed(2),
              isPreOrder,
            },
            loading: false,
          });
        } else {
          this.setState({ currentOrder: null, loading: false });
        }
      });

    // Listen to storage

    // let currentOrderId = this.props.appStore.currentOrderId; try to move this
    let appStore = this.props.appStore;
    if (window) {
      window.addEventListener("storage", function(event) {
        console.log("storage event");
        let currentOrderId = this.props.appStore.currentOrderId; //moved here

        if (event.storageArea[config.localStorageIdentifier]) {
          const stateParsed = JSON.parse(
            event.storageArea[config.localStorageIdentifier]
          );
          if (stateParsed.currentOrderId !== currentOrderId) {
            console.log(
              "State has changed - updating current order",
              stateParsed.currentOrderId,
              currentOrderId
            );
            appStore.updateCurrentOrderId(stateParsed.currentOrderId);
          }
        }
      });
    }
  };

  verifyItemsAvailibility = async (draftOrderDoc) => {
    // ckeck all items, if it not exists in DB or if ValidFor or QryGroup12 === 'N', remove item from order
    const verifiedItems = [];
    const unavailableItems = [];

    try {
      for (const item of draftOrderDoc.data().items) {
        // get db product linked to item
        const dbItem = await db
          .collection("products")
          .where("ItemCode", "==", item.code)
          .get();

        // check if product exists and is still activated
        if (!dbItem.empty) {
          const existingItem = dbItem.docs[0].data();
          if (
            existingItem.ValidFor === "Y" &&
            existingItem.QryGroup12 === "Y"
          ) {
            verifiedItems.push(item);
          } else {
            unavailableItems.push(item);
          }
        } else {
          unavailableItems.push(item);
        }
      }

      // If products are deactivated, update order only with available items.
      // Since draft is fetched with a snapshot, the order in front is updated real time
      if (unavailableItems.length) {
        console.log("[Draft order - items unavailable]");
        this.handleShowModal(
          <DeletedDraftProductsModal unavailableItems={unavailableItems} />,
          ""
        );

        await db
          .collection("orders")
          .doc(draftOrderDoc.id)
          .update({ items: verifiedItems });
      } else {
        console.log("[Draft order - All items available]");
      }
      return Promise.resolve();
    } catch (e) {
      console.log("[Draft order - error]", e);
    }
  };

  handleCreateNewDraftOrder = async (itemCode = null) => {
    this.props.appStore.setLoading(true, "Creating new order");

    // this.handleShowModal(<BackOrderModal />, "");

    try {
      const docRef = await db
        .collection(
          process.env.REACT_APP_ORDERS_COLLECTION
            ? process.env.REACT_APP_ORDERS_COLLECTION
            : "orders"
        )
        .add({
          customerName: this.props.appStore.currentAccount.name,
          customerCode: this.props.appStore.currentAccount.code,
          customerContactCode: this.props.appStore.currentAccount.id,
          title: `New Order (${dayjs().format("MMMM D YYYY")})`,
          items: [],
          userUpdated: this.props.appStore.currentUser.email,
          slpCode: this.props.appStore.currentAccount.slpCode,
          notes: null,
          notifyCustomer: false,
          status: "draft",
          payToCode:
            this.props.appStore.currentAccount.billTo &&
            this.props.appStore.currentAccount.billTo.code
              ? this.props.appStore.currentAccount.billTo.code
              : "",
          shipToCode:
            this.props.appStore.currentAccount.shipTo &&
            this.props.appStore.currentAccount.shipTo.code
              ? this.props.appStore.currentAccount.shipTo.code
              : "",
          isPreOrder: false,
          customerOrder: this.props.appStore.currentUser.type,
          updatedAt: timestamp.now(),
          createdAt: timestamp.now(),
        });

      this.props.appStore.updateCurrentOrderId(docRef.id);
      this.props.appStore.showCart();

      if (itemCode) {
        setTimeout(() => this.handleAddOrderItem(itemCode), 1500);
      }
      this.props.appStore.setLoading(false);
      console.log("AppProvider - show backOrderModal");
      this.handleShowModal(<BackOrderModal />, "");
    } catch (error) {
      this.props.appStore.setLoading(false);
      console.error("Error creating new draft order: ", error);
    }
  };

  handleShowEmptyCartModal = () => {
    this.handleShowModal(<EmptyCartModal />, "");
  };

  handleShowNewDraftOrderModal = (orderId) => {
    this.handleShowModal(<EmailDraftOrderModal orderId={orderId} />, "");
  };

  handleShowBackOrderModal = () => {
    this.handleShowModal(<BackOrderModal />, "");
  };

  handleEmptyCart = () => {
    db.collection(
      process.env.REACT_APP_ORDERS_COLLECTION
        ? process.env.REACT_APP_ORDERS_COLLECTION
        : "orders"
    )
      .doc(this.props.appStore.currentOrderId)
      .update({
        items: [],
        updatedAt: timestamp.now(),
      })
      .then(function(docRef) {})
      .catch(function(error) {
        console.error("Error writing document: ", error);
      });
  };

  handleAddOrderItem = async (itemCode, qty = null) => {
    if (!this.state.currentOrder) {
      // Auto create new order
      return this.handleCreateNewDraftOrder(itemCode);
    }

    let currentOrderItems = this.state.currentOrder.items;

    //console.log("CURRENT ORDER ITEMS", currentOrderItems);

    // Find out if item exists in order already
    const existingOrderItem = currentOrderItems.find(
      (item) => item.code === itemCode
    );

    if (existingOrderItem) {
      currentOrderItems.forEach((item) => {
        if (item.code === itemCode) {
          item.qty = qty ? qty : Number(item.qty) + 1;
        }
      });

      // Remove
      //if (this.state.currentOrder.status === "draft") {
      currentOrderItems = currentOrderItems.filter(
        (item, index) => item.qty > 0
      );
      //}
    } else {
      currentOrderItems.push({
        code: itemCode,
        qty: qty ? qty : 1,
        userUpdated: this.props.appStore.currentUser.email,
      });
    }

    // Lookup Product and update price etc
    currentOrderItems.forEach((item, idx) => {
      const product = this.handleLookupProduct(item.code);
      if (product) {
        item.name = product.ItemName;
        item.rsp = product.U_RSP;
        item.caseQty = product.CaseQty;
        item.available = product.Available;
        item.description = product.U_CatDesc;
        item.uomCode = product.SalUnitMsr;
        item.price = product.price;
        item.caseCode = product.caseCode;
        item.upc = product.UPC;
        item.xmasItem = product.ChristmasItem === "Y";
        item.springItem = product.SpringItem === "Y";
        item.unitPrice = product.unitPrice ? product.unitPrice : null;
        item.lineTotal = (item.price * item.qty).toFixed(2);
        // V2
        item.brand = product.Brand;
        item.caseWeight = product.CaseWeight;
        item.netWeight = product.NetWeight;
        item.caseDimensions = product.CaseDimensions;
        item.unitDimensions = product.UnitDimensions;
        item.imageName = product.PicturName;
        delete item.image;
        delete item.gatsbyImage;
      }
    });

    // console.log(
    //   "CART ITEM ADDED",
    //   currentOrderItems,
    //   JSON.parse(JSON.stringify(currentOrderItems))
    // );

    await db
      .collection(
        process.env.REACT_APP_ORDERS_COLLECTION
          ? process.env.REACT_APP_ORDERS_COLLECTION
          : "orders"
      )
      .doc(this.props.appStore.currentOrderId)
      .update({
        items: JSON.parse(JSON.stringify(currentOrderItems)),
        updatedAt: timestamp.now(),
      })
      .then(function(docRef) {})
      .catch(function(error) {
        console.error("Error writing document: ", error);
      });

    return true;
  };

  handleLookupProduct = (itemCode) => {
    //Find item
    let product = this.state.products.find(
      (item) => item.ItemCode === itemCode
    );

    //Find Prices
    let productPrice = false;
    if (this.props.appStore.account) {
      productPrice = this.props.appStore.account.prices.find(
        (item) => item.itemCode === itemCode
      );
    }

    product = {
      ...product,
      //...productPrice
      price: productPrice ? productPrice.price : 0,
      unitPrice: productPrice ? productPrice.unitPrice : 0,
    };

    return product;
  };

  render() {
    if (this.state.isAuthenticating) {
      return (
        <div
          className="fixed inset-0 z-50 flex justify-center min-h-screen align-center"
          style={{ background: "rgba(255, 255, 255, 0.7)" }}
        >
          <div className="inset-auto flex app-spinner" />
        </div>
      );
    }

    //We need this to refresh.
    //console.log("Current Order in Cart", this.props.appStore.currentOrderId);

    return (
      <AppContext.Provider
        value={{
          state: this.state,
          appStore: this.props.appStore,
          signOut: this.handleSignOut,
          signIn: this.gotoSignIn,
          signInWithGoogle: this.handleSignInWithGoogle,
          signInWithMicrosoft: this.handleSignInWithMicrosoft,
          sayHi: this.sayHi,
          createNewDraftOrder: this.handleCreateNewDraftOrder,
          showEmptyCartModal: this.handleShowEmptyCartModal,
          showProductModal: this.handleShowProductModal,
          showEmailDraftOrderModal: this.handleShowNewDraftOrderModal,
          showBackOrderModal: this.handleShowBackOrderModal,
          emptyCart: this.handleEmptyCart,
          addOrderItem: this.handleAddOrderItem,
          lookupProduct: this.handleLookupProduct,
          setProducts: this.handleSetProducts,
          showModal: this.handleShowModal,
          showModalPane: this.handleShowModalPane,
          closeModal: this.handleCloseModal,
          currentOrderId: this.props.appStore.currentOrderId,
        }}
      >
        <>
          {this.props.children}
          <Modal.PopUp
            show={this.state.showModal}
            showCloseBtn={false}
            closeModal={() =>
              this.setState({
                showModal: !this.state.showModal,
              })
            }
          >
            {this.state.modalComponent}
          </Modal.PopUp>
        </>
      </AppContext.Provider>
    );
  }
}
export default observer(AppProvider);
