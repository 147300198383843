const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
};

// async function setup() {
//   return app.initializeApp(config);
// }

// let instance;

// class Firebase {

//   constructor() {
//     if (instance) {
//       return instance;
//     }
//     firebase.initializeApp(config);
//     this.auth = firebase.auth();
//     this.instance = this;
//   }
// }

function checkLoginStatus() {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        resolve(user);
      } else {
        reject("not-logged-in");
      }
    });
  });
}

let getFirebase, db, auth, perf, timestamp, fieldvalue, firestore;

const setup = () => {
  try {
    if (typeof window !== "undefined") {
      const firebase = require("firebase/compat/app").default;
      require("firebase/compat/firestore");
      require("firebase/compat/auth");
      require("firebase/compat/storage");
      require("firebase/compat/performance");
      firebase.initializeApp(config);
      db = firebase.firestore();
      firestore = db;
      auth = firebase.auth();
      perf = firebase.performance();

      getFirebase = firebase;

      // console.log("Firebase initialized", config);

      timestamp = firebase.firestore.Timestamp;
      fieldvalue = firebase.firestore.FieldValue;
    }
    return getFirebase;
  } catch (err) {
    console.log(err);
  }
};

/*const databaseRef = firebase.database().ref();
export const todosRef = databaseRef.child("todos");*/

export {
  setup,
  checkLoginStatus,
  getFirebase,
  db,
  auth,
  timestamp,
  fieldvalue,
  perf,
  firestore,
};

/*import firebase from "firebase"
 
const config = {
  apiKey: "xxx",
  authDomain: "xxx",
  databaseURL: "xxx",
  projectId: "xxx",
  storageBucket: "xxx",
  messagingSenderId: "xxx",
  appId: "xxx",
}
 
firebase.initializeApp(config)
 
export const functions = firebase.functions()*/
