import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { IoIosAdd, IoIosClose, IoIosBug } from "react-icons/io";
import { withAppContext } from "../common/withAppContext";

const Property = ({ label, value }) => {
  return (
    <div className="mb-3">
      <h4 className="text-sm font-semibold opacity-75">{label}</h4>
      <p>{value ? value : " — "}</p>
    </div>
  );
};

const StatusDot = ({ status }) => {
  let color = "purple-600";
  if (status === "In Stock") {
    color = "green-600";
  } else if (status === "Out of Stock") {
    color = "red-600";
  }
  return (
    <div className={`w-3 h-3 rounded-full inline-block bg-${color} mr-2`} />
  );
};

const NewDraftOrderModal = ({ productCode, context }) => {
  const product = context.lookupProduct(productCode);
  let currentItemInCart = null;

  const [debugMode, setDebugMode] = React.useState(false);

  if (
    context &&
    context.state &&
    context.state.currentOrder &&
    context.state.currentOrder.items.length > 0
  ) {
    currentItemInCart = context.state.currentOrder.items.find(
      (item, idx) => item.code === product.ItemCode
    );
  }

  const isAdmin =
    context.state &&
    context.state.isAuthenticated &&
    context.appStore.currentUser &&
    context.appStore.currentUser.isAdmin;

  let isDisabled = false;

  if (
    (currentItemInCart &&
      currentItemInCart.LineStatus &&
      currentItemInCart.LineStatus === "C") ||
    (currentItemInCart &&
      currentItemInCart.LineStatus &&
      currentItemInCart.LineStatus === "O" &&
      (currentItemInCart.xmasItem || currentItemInCart.springItem))
  ) {
    isDisabled = true;
  }

  const disableAddToCartForOutOfStockOptions =
    context &&
    context.appStore &&
    context.appStore.getSetting("disableAddToCartForOutOfStockOptions");

  if (
    disableAddToCartForOutOfStockOptions &&
    product.RestockDate &&
    product.Status === "Out of Stock"
  ) {
    isDisabled = true;
  }

  if (debugMode) {
    return (
      <div className="max-w-4xl max-h-screen mx-auto bg-white rounded">
        <div className="relative flex-1 overflow-hidden rounded bg-theme-100">
          <button
            className="m-3 text-red-700 "
            onClick={(e) => {
              e.stopPropagation();
              setDebugMode(false);
            }}
          >
            <IoIosBug className="w-4 h-4 fill-current" />
          </button>
          <pre>{JSON.stringify(product, 0, 2)}</pre>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl max-h-screen mx-auto bg-white rounded">
      <div className="flex flex-col flex-1 overflow-hidden rounded bg-theme-100 sm:flex-row">
        <div className="relative w-full sm:w-1/2">
          {product.gatsbyImage && (
            <GatsbyImage
              image={product.gatsbyImage.gatsbyImageData}
              objectFit="contain"
              objectPosition="50% 50%"
              alt={product.ItemName}
              className="h-full w-full"
              imgStyle={{
                objectFit: "contain",
                backgroundColor: "#fff",
              }}
            />
          )}
          {isAdmin && (
            <button
              className="absolute top-0 left-0 m-3"
              onClick={(e) => {
                e.stopPropagation();
                setDebugMode(true);
              }}
            >
              <IoIosBug className="w-4 h-4 fill-current" />
            </button>
          )}
        </div>

        <div className="flex flex-col w-full sm:w-1/2">
          <div className="relative flex flex-col flex-1 p-6">
            <button
              className="absolute top-0 right-0 z-50 p-2 cursor-pointer text-dark hover:opacity-75"
              onClick={() => context.closeModal()}
            >
              <IoIosClose className="w-12 h-12 fill-current" />
            </button>
            <span className="mb-1 text-sm font-semibold">{product.Brand}</span>
            <h2 className="mb-1 text-xl font-semibold">{product.ItemName}</h2>
            <p className="mb-5 text-sm">{product.U_CatDesc}</p>
            <div className="flex flex-col justify-start mb-1">
              <Property label="Item Number" value={product.ItemCode} />
              <Property label="UPC Code" value={product.UPC} />
              <Property label="Case Code" value={product.caseCode} />
              <Property
                label="Unit Dimensions"
                value={product.UnitDimensions}
              />
              <Property label="Unit Weight" value={product.NetWeight} />
              <Property
                label="Case Dimensions"
                value={product.CaseDimensions}
              />
              <Property label="Case Weight" value={product.CaseWeight} />
              <Property
                label="Pallet Information"
                value={product.PalletDetails}
              />
              <Property
                label="GST/HST/QST"
                value={product.VATLiable === "Y" ? "Yes" : "No"}
              />
            </div>
            {context &&
              context.appStore &&
              context.appStore.account &&
              product && (
                <div className="flex items-center justify-between mt-auto">
                  <div className="flex items-center px-2 text-lg font-semibold">
                    <StatusDot status={product.Status} />{" "}
                    <span>{product.Status}</span>
                  </div>
                  <button
                    className="btn btn-primary hover:text-dark"
                    onClick={(e) => {
                      e.stopPropagation();
                      context.addOrderItem(product.ItemCode);
                    }}
                    disabled={isDisabled}
                  >
                    <span>Add to Cart</span>
                    {currentItemInCart ? (
                      <span className="px-2 py-1 ml-2 font-semibold bg-white rounded-sm">
                        {currentItemInCart.qty}
                      </span>
                    ) : (
                      <IoIosAdd className="w-4 h-4 ml-2 fill-current" />
                    )}
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAppContext(NewDraftOrderModal);
