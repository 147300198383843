import React from "react";
import { onSnapshot } from "mobx-state-tree";
import browserUpdate from 'browser-update';
import * as Sentry from "@sentry/browser";
import './src/ReactotronConfig'
import ErrorBoundry from "./src/errors/ErrorBoundry";
import AppProvider from "./src/components/common/AppProvider";
import config from "./src/services/ConfigService";

//Models
import { AppStore } from "./src/stores/AppStore";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://78b0b2419f15402b9c9ae5958daf89a5@sentry.io/1762925"
  });
}

browserUpdate({
  required:{e:-4,f:-3,o:-3,s:-1,c:-3},
  insecure:true
});

/**
 * APP INIT
 * Always load localStorage first.
 */

let initialState = {};


if (localStorage.getItem(config.localStorageIdentifier)) {
  const cachedState = JSON.parse(
    localStorage.getItem(config.localStorageIdentifier)
  );
  console.log(
    `💥 Current App version: ${config.appVersion}, Cached App version: ${
      cachedState.version
    }`
  );

  if (config.appVersion === cachedState.version) {
    console.log(`✅ Loaded in cached state`);
    initialState = cachedState;
  } else {
    console.log('Outdated Cache')
  }
}

const appStore = AppStore.create(initialState);

// Auto save
onSnapshot(appStore, snapshot => {
  return localStorage.setItem(
    config.localStorageIdentifier,
    JSON.stringify(snapshot)
  );
});

export const wrapRootElement = ({ element }) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return (
      <AppProvider appStore={appStore} className={`App`}>
        {element}
      </AppProvider>
    );
  }
  return (
    <ErrorBoundry>
      <AppProvider appStore={appStore} className={`App`}>
        {element}
      </AppProvider>
    </ErrorBoundry>
  );
};
