import { useStaticQuery, graphql } from "gatsby";
import { buildGatsbyImage } from "./ImageService";
import { db } from "../services/firebaseService";

let listings = [];
export const GetProducts = () => {
  const products = useStaticQuery(
    graphql`
      query AllProducts {
        products: allDovetaleProduct(sort: { ItemCode: ASC }) {
          edges {
            node {
              ItemCode
              ItemName
              FrgnName
              Brand
              ItmsGrpCod
              caseCode
              UPC
              VATLiable
              OnHand
              IsCommited
              Available
              Status
              RestockDate
              SalUnitMsr
              CaseQty
              PicturName
              UnitDimensions
              CaseDimensions
              CaseWeight
              U_RSP
              NetWeight
              U_WebCat
              U_CatDesc
              PalletDetails
              slug
              Artisan
              Natural
              Regional
              Organic
              Kosher
              GlutenFree
              FairTrade
              New
              ChristmasItem
              SpringItem
              Promo
              TopSeller
              Vegan
              images {
                path
                url
                fileName
                fullName
                name
                size
                base64
              }
            }
          }
        }
        fallbackImage: file(name: { eq: "fallback-product-image" }) {
          childImageSharp {
            gatsbyImageData(
              width: 526
              quality: 92
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    `
  );

  const preparedProductsArr = prepareProducts(
    products.products.edges,
    products.fallbackImage.childImageSharp
  );

  return preparedProductsArr;
};

export const getMyListings = async (accountCode) => {
  if (accountCode) {
    try {
      const snapshot = await db
        .collection("invoices")
        .where("CardCode", "==", accountCode)
        .get();
      if (snapshot.size) {
        let itemsArr = [];

        snapshot.forEach((doc) => {
          const invoice = doc.data();

          if (invoice.items && Object.values(invoice.items).length > 0) {
            Object.values(invoice.items).forEach((item) => {
              const itemCurrentlyExists = itemsArr.find(
                (existingItem) => existingItem.ItemCode === item.ItemCode
              );
              if (itemCurrentlyExists) {
                itemCurrentlyExists.count = itemCurrentlyExists.count + 1;
              } else {
                itemsArr.push({
                  ItemCode: item.ItemCode,
                  count: 1,
                });
              }
            });
          }
        });

        itemsArr = itemsArr.sort((a, b) => a.count - b.count).reverse();
        listings = itemsArr;
      }
    } catch (e) {
      console.log("error", e);
    }
  }
};

export const isInListings = (productCode) => {
  return listings.map((item) => item.ItemCode).includes(productCode);
};

/**
 * IMAGE - GATSBY IMAGE
 */

const prepareProducts = (productsArr, fallbackImage) => {
  const preparedProducts = productsArr.map(({ node }) => {
    let product = node;
    if (product.images && product.images.length > 0) {
      product.gatsbyImage = buildGatsbyImage(product.images);
    } else {
      product.gatsbyImage = fallbackImage;
    }

    // Options
    const productOptions = [];
    product.GlutenFree === "Y" &&
      productOptions.push({ name: "Gluten Free", value: "GlutenFree" });
    product.Artisan === "Y" &&
      productOptions.push({ name: "Artisan", value: "Artisan" });
    product.Natural === "Y" &&
      productOptions.push({ name: "Natural", value: "Natural" });
    product.Regional === "Y" &&
      productOptions.push({ name: "Regional", value: "Regional" });
    product.Organic === "Y" &&
      productOptions.push({ name: "Organic", value: "Organic" });
    product.Kosher === "Y" &&
      productOptions.push({ name: "Kosher", value: "Kosher" });
    product.FairTrade === "Y" &&
      productOptions.push({ name: "Fair Trade", value: "FairTrade" });
    product.Vegan === "Y" &&
      productOptions.push({ name: "Vegan", value: "Vegan" });

    product.options = productOptions;

    return product;
  });
  return preparedProducts;
};
