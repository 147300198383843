import React, { Component } from "react";
//import SomeErrorReportingTool from './SomeErrorReportingTool';

const ErrorView = () => (
  <div className="section w-screen h-screen">
    <div className="container mx-auto max-w-sm">
      <div className="block p-6 md:px-8 rounded-t bg-theme-300 text-center">
        <h3 className="text-3xl text-theme-900 font-semibold mb-1">
          Oops. Something happened that shouldn't have.
        </h3>
        <p className="text-sm text-theme-700 mb-1" />
      </div>
    </div>
  </div>
);

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      info: "",
      error: ""
    };
  }

  componentDidCatch(error, info) {
    console.log("------ did catch")
    this.setState({ hasError: true, info, error });
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      console.log(`Error: ${error}`);
      console.log(`ErrorInfo: ${JSON.stringify(info)}`);
    } else {
      //SomeErrorReportingTool.report(error, info);
    }
  }

  render() {
    return this.state.hasError ? (
      <ErrorView/>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
