import { types } from "mobx-state-tree";
import { CurrentUser, Account } from "./UserStore";
import config from "../services/ConfigService";

/**
 * Setting
 */

const Setting = types
.model({
  name: types.string,
  meta: types.frozen()
})
.actions(self => ({
  changeMeta(newMeta) {
    self.meta = newMeta;
  }
}));

/**
 * App
 */

export const AppStore = types
  .model({
    siteTitle: "Dovetale V2",
    displayCart: types.optional(types.boolean, false),
    currentOrderId: types.maybeNull(types.string),
    currentUser: types.maybeNull(CurrentUser),
    currentAccount: types.maybeNull(types.reference(Account)),
    loading: false,
    loadingMessage: types.maybeNull(types.string),
    version: config.appVersion,
    settings: types.optional(types.array(Setting), []),
  })
  .actions(self => ({
    changeSiteTitle(newSiteTitle) {
      self.siteTitle = newSiteTitle;
    },
    setupCurrentUser(newCurrentUser) {

      // There's no point readding the user if they already exist
      if(self.currentUser && self.currentUser.email === newCurrentUser.email){
        console.log(`CURRENT USER ${self.currentUser.email} ALREADY EXISTS`);
      } else {
        console.log(`NO CURRENT USER EXISTS, SETTING UP...`);
        self.currentUser = newCurrentUser;
        // We should figure out what type of user they are here - fetch user info + available accounts
        self.currentUser.setup();
      }
      
    },
    setLoading(isLoading = false, message = null) {
      self.loading = isLoading;
      self.loadingMessage = message;
    },
    wipeCurrentUser() {
      //self.currentUser.remove();
      self.currentAccount = null;
      self.currentUser = null;
    },
    updateCurrentAccount(newAccountRef){
      self.currentAccount = newAccountRef;
      self.currentAccount.setup();

      self.currentOrderId = null;
      self.displayCart = false;
    },
    showCart(){
      self.displayCart = true;
    },
    hideCart(){
      self.displayCart = false;
    },
    toggleCart(){
      console.log('TOGGLE CART', self.displayCart);
      self.displayCart = !self.displayCart;
    },
    updateCurrentOrderId(newOrderId){
      console.log('Active order: ', newOrderId);
      self.currentOrderId = newOrderId;
    },
    setSetting(settingName, settingMeta) {
      const existingSetting = self.settings.find(
        setting => setting.name === settingName
      );
      if (existingSetting) {
        existingSetting.changeMeta(settingMeta);
      } else {
        self.settings.push({
          name: settingName,
          meta: settingMeta
        });
      }
    },
  }))
  .views(self => ({
    /*get totalDocs() {
      return self.docs.length;
    },*/
    get isLoading() {
      return self.loading;
    },
    get account() {
      try { return self.currentAccount } catch { return false }
      //return self.currentAccount && self.currentAccount.name ? self.currentAccount : null;
    },
    getSetting(settingName) {
      const existingSetting = self.settings.find(
        setting => setting.name === settingName
      );
      return (existingSetting && existingSetting.meta) || null
    }
  }));
