import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import "react-day-picker/lib/style.css";
import { db, timestamp } from "../../services/firebaseService";
import { withAppContext } from "../../components/common/withAppContext";

const EmailDraftOrderModal = (props) => {
  const {
    values,
    touched,
    errors,
    status,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    context,
  } = props;

  const isAdmin =
    context.state &&
    context.state.isAuthenticated &&
    context.appStore.currentUser &&
    context.appStore.currentUser.isAdmin;

  if (status && status.message) {
    return (
      <div
        className="max-w-lg p-4 mx-auto bg-white rounded"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="w-full" onSubmit={handleSubmit}>
          <h1 className="mb-5 font-serif text-2xl lg:text-2xl">
            Share Draft Order
          </h1>
          <div className="flex p-4 my-3 rounded bg-theme-400">
            <IoIosCheckmarkCircleOutline className="w-6 h-6 mr-2 fill-current" />{" "}
            <span>{status && status.message}</span>
          </div>
          <div className="flex buttons">
            <button
              className="ml-auto btn btn-lg"
              onClick={() => context.closeModal()}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    );
  }
  if (!context.state.currentOrder) {
    return <div className="w-6 h-6 spinner" />;
  }
  return (
    <div
      className="max-w-lg p-4 mx-auto bg-white rounded"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <form className="w-full" onSubmit={handleSubmit}>
        <h1 className="mb-5 font-serif text-2xl lg:text-2xl">
          Share Draft Order
        </h1>

        {errors.general && (
          <div className="notification notification-danger">
            {errors.general}
          </div>
        )}
        <div className="flex flex-wrap mb-3 -mx-3">
          <label className="w-full px-3 mb-3 md:w-1/2 md:mb-0">
            <span className="text-theme-700">Recipient name</span>
            <input
              name="name"
              type="text"
              placeholder=""
              maxLength="50"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!isAdmin}
              className={
                errors.name && touched.name
                  ? "form-input mt-1 block w-full border-red-500"
                  : "form-input mt-1 block w-full"
              }
            />
            {errors.name && touched.name && (
              <div className="mt-2 text-xs text-red-500 firstletter">
                {errors.name}
              </div>
            )}
          </label>
          <label className="w-full px-3 mb-3 md:w-1/2 md:mb-0">
            <span className="text-theme-700">Email address</span>
            <input
              name="emailTo"
              type="text"
              placeholder=""
              maxLength="50"
              value={values.emailTo}
              onChange={handleChange}
              onBlur={handleBlur}
              // disabled={!isAdmin}
              disabled={true}
              className={
                errors.emailTo && touched.emailTo
                  ? "form-input mt-1 block w-full border-red-500"
                  : "form-input mt-1 block w-full"
              }
            />
            {errors.emailTo && touched.emailTo && (
              <div className="mt-2 text-xs text-red-500 firstletter">
                {errors.emailTo}
              </div>
            )}
            <p className="mb-3 text-sm">CC: {values.ccTo}</p>
          </label>
          <input
            name="emailFrom"
            type="hidden"
            placeholder=""
            maxLength="50"
            value={values.emailFrom}
          />
        </div>
        <div className="flex flex-wrap mb-3">
          <label className="w-full">
            <span className="text-theme-700">Message</span>
            <textarea
              name="message"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              rows="7"
              maxLength="254"
              className={
                errors.message && touched.message
                  ? "form-textarea mt-1 block w-full border-red-500"
                  : "form-textarea mt-1 block w-full"
              }
            />
            {errors.message && touched.message && (
              <div className="mt-2 text-xs text-red-500 firstletter">
                {errors.message}
              </div>
            )}
          </label>
          <span className="block w-full mt-3 mb-5 text-sm text-right text-theme-700">
            {254 - values.message.length} characters remaining.
          </span>
        </div>

        {context.state.currentOrder &&
          context.state.currentOrder.isPreOrder && (
            <p className="mb-3">
              * Your order contains Pre-Order items. Shipping dates will be
              affected.
            </p>
          )}

        {context.appStore.currentUser.email === values.emailTo && (
          <p className="mb-5 italic text-theme-700">
            Note: You're sending this to your email:{" "}
            <span className="underline">{values.emailTo}</span>
          </p>
        )}

        <div className="flex buttons">
          <button className="btn btn-lg" onClick={() => context.closeModal()}>
            Cancel
          </button>
          <button
            className={`btn btn-primary btn-lg ml-auto ${
              isSubmitting ? "spinner" : ""
            }`}
            type="submit"
            disabled={isSubmitting}
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default withAppContext(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props) => ({
      name:
        props.context.state.currentOrder &&
        props.context.state.currentOrder.share &&
        props.context.state.currentOrder.share.name
          ? props.context.state.currentOrder.share.name
          : props.context.appStore.account.name || "",
      emailTo: props.context.appStore.account.email || "",
      ccTo:
        props.context.state.currentOrder &&
        props.context.state.currentOrder.share &&
        props.context.state.currentOrder.share.ccTo
          ? props.context.state.currentOrder.share.ccTo
          : props.context.appStore.account.salesEmail || "",
      emailFrom:
        props.context.state.currentOrder &&
        props.context.state.currentOrder.share &&
        props.context.state.currentOrder.share.emailFrom
          ? props.context.state.currentOrder.share.emailFrom
          : props.context.appStore.currentUser.email !==
            props.context.appStore.account.email
          ? props.context.appStore.currentUser.email
          : "orders@dovetalecollections.com" || "",
      message:
        props.context.state.currentOrder &&
        props.context.state.currentOrder.share &&
        props.context.state.currentOrder.share.message
          ? props.context.state.currentOrder.share.message
          : "",
      currentOrderId: props.context.state.currentOrderId,
      from: props.context.appStore.currentUser.displayName,
    }),
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .max(50)
        .required("Required"),
      emailTo: Yup.string()
        .email()
        .max(50)
        .required("Required"),
      emailFrom: Yup.string()
        .email()
        .max(50)
        .required("Required"),
      message: Yup.string().max(254),
    }),

    handleSubmit: async (values, { setSubmitting, setErrors, setStatus }) => {
      setSubmitting(true);
      try {
        await db
          .collection(process.env.REACT_APP_ORDERS_COLLECTION ? process.env.REACT_APP_ORDERS_COLLECTION : "orders")
          .doc(values.currentOrderId)
          .update({
            share: {
              name: values.name,
              emailTo: values.emailTo,
              ccTo: values.ccTo,
              message: values.message,
              from: values.from,
              fromEmail: values.emailFrom,
            },
            updatedAt: timestamp.now(),
          })
          .then(function() {
            console.error("Updated order with share values ok");
          })
          .catch(function(error) {
            console.error("Error writing document: ", error);
          });

        // CALL API
        const emailDraft = await fetch(
          `https://dtc-app-2.firebaseapp.com/api/v1/order-email/${
            values.currentOrderId
          }`,
          {
            method: "GET",
            // headers: {
            //   "Content-Type": "application/json"
            // }
          }
        );

        const emailDraftResponse = await emailDraft.json();

        if (!emailDraft.ok) {
          throw new Error(
            `Email for Draft Order ${
              values.currentOrderId
            } could not be sent. Please try later. Reason: ${emailDraftResponse.message ||
              emailDraftResponse}`
          );
        }

        // //Sentry.captureMessage("Call to API OK (#" + orderId + ")", "info");

        setSubmitting(false);
        return setStatus({
          ok: true,
          message: "Draft order sent to " + values.emailTo,
        });
      } catch (e) {
        console.log(e);
        setSubmitting(false);
        return setErrors({ general: e.message });
      }
    },
  })(EmailDraftOrderModal)
);
