import React, { useEffect } from "react";
import { withAppContext } from "../common/withAppContext";
import DataGrid from "../DataGrid";
import { GatsbyImage } from "gatsby-plugin-image";

const DeletedDraftProductsModal = ({ context, unavailableItems }) => {
  useEffect(() => {
    if (!unavailableItems.length) context.closeModal();
  }, []);

  return (
    <div className="bg-white max-w-6xl p-4 mx-auto rounded text-center ">
      <h1 className="mb-5 font-serif text-2xl lg:text-2xl">
        Unavailable Items
      </h1>
      <p className="mb-5">
        The following products are no longer available and have been removed
        from your order:
      </p>
      {unavailableItems?.length && (
        <DataGrid
          data={unavailableItems}
          height={650}
          rowHeight={80}
          rowKey="nonAvailableProducts"
          key={JSON.stringify(unavailableItems.map((item) => item.code))}
          fixed
          columns={[
            {
              key: "item",
              dataKey: "item",
              title: "Item #",
              width: 100,
              cellRenderer: ({ rowData, ...rest }) => rowData.code,
            },
            {
              key: "itemImage",
              dataKey: "itemImage",
              title: "Item Image",
              width: 100,
              cellRenderer: ({ rowData, ...rest }) => {
                console.log("cell render");
                const product = context.lookupProduct("CT100A");
                console.log("product", product);
                return product.gatsbyImage ? (
                  <GatsbyImage
                    image={product.gatsbyImage.gatsbyImageData}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt={rowData.code}
                    className="w-16 h-16 mt-1"
                    imgStyle={{
                      objectFit: "cover",
                      backgroundColor: "#fff",
                    }}
                  />
                ) : null;
              },
            },
            {
              key: "itemName",
              dataKey: "itemName",
              title: "Item Name",
              width: 300,
              cellRenderer: ({ rowData, ...rest }) => rowData.name,
            },
            {
              key: "itemQuantity",
              dataKey: "itemQuantity",
              title: "Quantity",
              width: 100,
              cellRenderer: ({ rowData, ...rest }) => rowData.qty,
            },
          ]}
        />
      )}

      <div className="flex flex-col mt-2 md:flex-row justify-around items-center">
        <button
          className="btn btn-primary"
          onClick={() => {
            context.closeModal();
          }}
        >
          <span>Ok</span>
        </button>
      </div>
    </div>
  );
};

export default withAppContext(DeletedDraftProductsModal);
