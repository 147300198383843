import { types, flow, getParent, getRoot } from "mobx-state-tree";
import _ from "lodash";
import { auth, db } from "../services/firebaseService";
import { getMyListings } from "../services/ProductsService";

const Orders = types.model({
  docNum: types.identifier,
  shipTo: types.maybeNull(types.string),
  poNumber: types.maybeNull(types.string),
  orderDate: types.maybeNull(types.string),
  requestedShipDate: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  priceTotal: types.maybeNull(types.number),
  paidToDate: types.maybeNull(types.number),
  balance: types.maybeNull(types.number),
  shippedBy: types.maybeNull(types.string),
  trackingNumber: types.maybeNull(types.string)
});

const QuickOrderItems = types.model({
  code: types.identifier,
  brand: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  qty: types.maybeNull(types.number),
  uomCode: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  restockDate: types.maybeNull(types.string)
});

const ShippingAddress = types.model({
  code: types.identifier,
  address1: types.maybeNull(types.string),
  address2: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  stateProvince: types.maybeNull(types.string),
  postalCode: types.maybeNull(types.string),
  isDefault: types.maybeNull(types.string),
  addressType: types.maybeNull(types.string)
});

const BillingAddress = types.model({
  code: types.identifier,
  address1: types.maybeNull(types.string),
  address2: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  stateProvince: types.maybeNull(types.string),
  postalCode: types.maybeNull(types.string),
  isDefault: types.maybeNull(types.string),
  addressType: types.maybeNull(types.string)
});

const Contacts = types.model({
  contactCode: types.identifierNumber,
  name: types.maybeNull(types.string),
  tel1: types.maybeNull(types.string),
  tel2: types.maybeNull(types.string),
  cell: types.maybeNull(types.string),
  fax: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  active: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string)
});

const Prices = types.model({
  itemCode: types.identifier,
  price: types.maybeNull(types.number),
  caseQty: types.maybeNull(types.number),
  unitPrice: types.maybeNull(types.number)
});

export const Account = types
  .model({
    id: types.identifier,
    code: types.string,
    cardName: types.string,
    email: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    listNum: types.maybeNull(types.number),
    shipTo: types.maybeNull(types.reference(ShippingAddress)),
    billTo: types.maybeNull(types.reference(BillingAddress)),
    groupNum: types.maybeNull(types.number),
    paymentGroup: types.maybeNull(types.string),
    slpCode: types.maybeNull(types.number),
    slpName: types.maybeNull(types.string),
    salesEmail: types.maybeNull(types.string),
    shippingAddresses: types.optional(types.array(ShippingAddress), []),
    billingAddresses: types.optional(types.array(BillingAddress), []),
    contacts: types.optional(types.array(Contacts), []),
    prices: types.optional(types.array(Prices), []),
    quickOrderItems: types.optional(types.array(QuickOrderItems), []),
    orders: types.optional(types.array(Orders), [])
  })
  .actions(self => ({
    setup: flow(function*() {
      console.log("SETUP ACCOUNT");
      const root = getRoot(self);
      root.setLoading(true, "Loading account details");
      try {

        // Get firebase Customer

        let firebaseCustomerQuery = yield db
          .collection("customers")
          .where('CardCode', '==', self.code)
          .limit(1)
          .get();

        if (firebaseCustomerQuery.empty) {
          throw new Error(`CUSTOMER DOES NOT EXIST WITH CODE ${self.code}`);
        }

        const account = firebaseCustomerQuery.docs[0].data();

        getMyListings(self.code)
        console.log("ACCOUNT FETCHED OK");

        // Todo - check to if a customer has a matching shipping and billing address to the def. If not set as null.

        self.name = account.CardName;
        self.address = account.Address;
        self.listNum = account.ListNum;
        self.groupNum = account.GroupNum;
        self.paymentGroup = account.PymntGroup;
        self.slpCode = account.SlpCode;
        self.slpName = account.SlpName;
        self.salesEmail = account.SalesEmail;

        // Fetch Shipping Addresses

        root.setLoading(true, "Loading shipping addresses");

        const shippingAddresses = account.shippingAddresses;

        const filteredShippingAddresses = _.uniqBy(
          shippingAddresses,
          "ShipToCode"
        );

        console.log(
          `FETCHED ${
            filteredShippingAddresses.length
          } ACCOUNT SHIPPING ADDRESSES`
        );

        self.shippingAddresses = [];

        let doesShippingMatch = false;

        _.uniqBy(filteredShippingAddresses, "ShipToCode").map(
          (address, idx) => {
            if (address.ShipToCode === account.ShipToDef) {
              doesShippingMatch = true;
            }
            return self.shippingAddresses.push({
              code: address.ShipToCode,
              address1: address.ShipToAddress1,
              address2: address.ShipToAddress2,
              city: address.ShipToCity,
              stateProvince: address.ShipToStateProvince,
              postalCode: address.ShipToPostalCode,
              isDefault: address.IsDefault,
              addressType: address.AdresType
            });
          }
        );

        //self.shipTo = null; //self.shipTo = account.ShipToDef ? account.ShipToDef : null;
        self.shipTo = doesShippingMatch ? account.ShipToDef : null;

        // Fetch Billing Addresses

        root.setLoading(true, "Loading billing addresses");

        const billingAddresses = account.billingAddresses;
        const filteredBillingAddresses = _.uniqBy(
          billingAddresses,
          "BillToCode"
        );

        console.log(
          `FETCHED ${filteredBillingAddresses.length} ACCOUNT BILLING ADDRESSES`
        );

        self.billingAddresses = [];

        let doesBillingMatch = false;

        filteredBillingAddresses.map((address, idx) => {
          if (address.code === account.BillToDef) {
            doesBillingMatch = true;
          }
          return self.billingAddresses.push({
            code: address.BillToCode,
            address1: address.BillToAddress1,
            address2: address.BillToAddress2,
            city: address.BillToCity,
            stateProvince: address.BillToStateProvince,
            postalCode: address.BillToPostalCode,
            isDefault: address.IsDefault,
            addressType: address.AdresType
          });
        });

        // Default billing address set
        if (filteredBillingAddresses.length > 1) {
          // If we have multiple address, let the user choose.
          self.billTo = null;
        } else if (doesBillingMatch) {
          // If we have an address that matches the current Def, set that.
          self.billTo = account.BilltoDef;
        } else if (filteredBillingAddresses.length === 1) {
          // If we have one single address, set it as the default
          self.billTo = filteredBillingAddresses[0].code;
        }

        // Fetch Contacts

        root.setLoading(true, "Loading account contacts");

        const contacts = account.contacts;

        console.log(`FETCHED ${contacts.length} ACCOUNT CONTACTS`);

        self.contacts = [];

        contacts.map((contact, idx) => {
          return self.contacts.push({
            contactCode: contact.CntctCode,
            name: contact.Name,
            tel1: contact.Tel1,
            tel2: contact.Tel2,
            cell: contact.Cellolar,
            fax: contact.Fax,
            email: contact.E_MaiL,
            updatedAt: String(contact.updateDate.toDate()),
            active: contact.Active,
            firstName: contact.FirstName,
            lastName: contact.LastName
          });
        });

        // Fetch price list

        root.setLoading(true, "Loading price list");

        let firebasePriceListsQuery = yield db
          .collection("priceLists")
          .where('PriceList', '==', self.listNum)
          .get();

        if (firebasePriceListsQuery.empty) {
          throw new Error(`NO PRICES EXIST WITH CODE ${self.listNum}`);
        }

        console.log(`FETCHED ${firebasePriceListsQuery.docs.length} ACCOUNT PRICES WITH LISTNUM ${self.listNum}`);

        self.prices = [];

        firebasePriceListsQuery.docs.map((price, idx) => {
          price = price.data();
          return self.prices.push({
            itemCode: price.ItemCode,
            price: price.Price,
            caseQty: price.CaseQty,
            unitPrice: price.UnitPrice
          });
        });

        // Fetch Quick Order Items

        // root.setLoading(true, "Loading quick order items");

        // const fetchAccountQuickOrderItems = yield fetch(
        //   "https://dtc-app-2.firebaseapp.com/api/v1/customer-quick-order-items",
        //   {
        //     method: "POST",
        //     headers: {
        //       Authorization: "Bearer " + root.currentUser.accessToken,
        //       "Content-Type": "application/json"
        //     },
        //     body: JSON.stringify({
        //       code: self.code
        //     })
        //   }
        // );

        // const quickOrderItems = yield fetchAccountQuickOrderItems.json();

        // if (quickOrderItems.length > 0) {
        //   console.log(
        //     `FETCHED ${quickOrderItems.length} ACCOUNT QUICK ORDER ITMS`
        //   );

        //   self.quickOrderItems = [];

        //   quickOrderItems.map((quickOrderItem, idx) => {
        //     return self.quickOrderItems.push({
        //       code: quickOrderItem.ItemCode,
        //       brand: quickOrderItem.Brand,
        //       name: quickOrderItem.ItemName,
        //       qty: quickOrderItem.Quantity,
        //       uomCode: quickOrderItem.UOMCode,
        //       status: quickOrderItem.Status,
        //       restockDate: quickOrderItem.RestockDate
        //     });
        //   });
        // } else {
        //   console.log(`FETCHED 0 ACCOUNT QUICK ORDER ITMS`);
        // }

        // Fetch Order

        // root.setLoading(true, "Loading orders");

        // const orders = [].concat.apply([], [account.invoices, account.orders]);

        // if (orders.length > 0) {
        //   console.log(`FETCHED ${orders.length} ACCOUNT ORDERS. (Orders: ${account.orders.length}). (Invoices: ${account.invoices.length})`);

        //   self.orders = [];

        //   orders.map((order, idx) => {
        //     return self.orders.push({
        //       docNum: String(order.DocNum),
        //       shipTo: order.Address2,
        //       poNumber: order.NumAtCard,
        //       orderDate: String(order.DocDate.toDate()),
        //       requestedShipDate: String(order.DocDueDate.toDate()),
        //       status: order.Status,
        //       type: order.DocType,
        //       priceTotal: order.DocTotal,
        //       paidToDate: order.PaidToDate,
        //       balance: order.Balance,
        //       shippedBy: order.TrnspName,
        //       trackingNumber: order.TrackNo
        //     });
        //   });
        // } else {
        //   console.log(`FETCHED 0 ACCOUNT ORDERS`);
        // }

        root.setLoading(false);
      } catch (error) {
        console.log("ACCOUNT ERROR FETCHED", error);
        alert(
          "Sorry, there was an issue loading your account. Try logging in again..."
        );
        console.log("👤 SIGNING OUT... DUE TO ACCOUNT LOADING ISSUE");
        root.hideCart();
        root.updateCurrentOrderId(null);

        auth.signOut();
        setTimeout(() => window.location.reload(true), 1000);

        root.setLoading(false);
      }
    }),
    // fetchOrders: flow(function*() {
    //   console.log("FETCH ORDERS");
    //   const root = getRoot(self);
    //   try {
    //     // Fetch Order
    //     root.setLoading(true, "Loading orders");

    //     const fetchAccountOrders = yield fetch(
    //       "https://dtc-app-2.firebaseapp.com/api/v1/customer-orders",
    //       {
    //         method: "POST",
    //         headers: {
    //           Authorization: "Bearer " + root.currentUser.accessToken,
    //           "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify({
    //           code: self.code
    //         })
    //       }
    //     );

    //     const orders = yield fetchAccountOrders.json();

    //     if (orders.length > 0) {
    //       console.log(`FETCHED ${orders.length} ACCOUNT ORDERS`);

    //       self.orders = [];

    //       orders.map((order, idx) => {
    //         return self.orders.push({
    //           docNum: String(order.DocNum),
    //           shipTo: order.Address2,
    //           poNumber: order.NumAtCard,
    //           orderDate: order.DocDate,
    //           requestedShipDate: order.DocDueDate,
    //           status: order.Status,
    //           type: order.DocType,
    //           priceTotal: order.DocTotal,
    //           paidToDate: order.PaidToDate,
    //           balance: order.Balance,
    //           shippedBy: order.TrnspName,
    //           trackingNumber: order.TrackNo
    //         });
    //       });
    //     } else {
    //       console.log(`FETCHED 0 ACCOUNT ORDERS`);
    //     }

    //     root.setLoading(false);
    //   } catch (error) {
    //     console.log("ACCOUNT ERROR FETCHED", error);
    //     alert(
    //       `Your session has timed out due to inactivity, and your current order has been saved as a draft. To access your draft order, please login in again and navigate to "Orders --> Draft Orders" to resume your order.`
    //     );
    //     console.log("👤 SIGNING OUT... DUE TO ACCOUNT LOADING ISSUE");
    //     root.hideCart();
    //     root.updateCurrentOrderId(null);

    //     getFirebase.auth().signOut();
    //     setTimeout(() => window.location.reload(true), 1000);

    //     root.setLoading(false);
    //   }
    // })
  }));

/**
 * User
 */

export const CurrentUser = types
  .model({
    displayName: types.maybe(types.string, ""),
    email: types.string,
    emailVerified: types.boolean,
    accessToken: types.string,
    accounts: types.optional(types.array(Account), []),
    type: "C",
    isAdmin: types.optional(types.boolean, false)
  })
  .actions(self => ({
    setup: flow(function*() {
      console.log("SETUP USER");
      getParent(self).setLoading(true, "Loading your details");
      try {
        const fetchUser = yield fetch(
          "https://dtc-app-2.firebaseapp.com/api/v1/get-sync-user",
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + self.accessToken,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              email: self.email
            })
          }
        );

        const user = yield fetchUser.json();

        // console.log(user, " USER FETCHED");

        if (!user.id) {
          console.log("USER DOES NOT EXIST IN DATABASE ");
          getParent(self).setLoading(false);
          return;
        }

        // Get firebase User
        let firebaseUser = yield db
          .collection("users")
          .doc(user.id)
          .get();

        if (!firebaseUser.exists) {
          console.log("USER HAS NO CUSTOMERS");
          return;
        }
        firebaseUser = firebaseUser.data();

        console.log("😍 USER IS LOGGED IN");

        self.type = firebaseUser.userType;

        let accounts = [];

        if (firebaseUser.isAdmin) {
          console.log("USER IS ADMIN");
          self.isAdmin = true;

          let allCustomers = yield db
            .collection("customers")
            .orderBy("CardCode")
            .get();

          if (allCustomers.empty) {
            console.log("No matching customers.");
            return false;
          }

          allCustomers.forEach((customer) => {
            customer = customer.data();
            if (customer.CardCode) {
              customer.contacts.forEach(customerContact => {
                if(customerContact.E_MaiL === null) return
                accounts.push({
                  CntctCode: String(customerContact.CntctCode),
                  CardCode: customer.CardCode,
                  CardName: customer.CardName,
                  Name: customerContact.Name,
                  E_MaiL: customerContact.E_MaiL
                });
              })
            }
          });

          console.log('ADMIN: All Accounts', accounts.length)
        } else {
          accounts = firebaseUser.customers;
        }

        accounts.forEach((account, idx) => {
          self.addAccount({
            id: String(account.CntctCode),
            code: account.CardCode,
            cardName: account.CardName,
            name: account.Name,
            email: account.E_MaiL
          });
        });

        console.log("GOT USER ACCOUNTS");

        if (accounts.length === 0) {
          //Do nothing
        } else if (accounts.length > 1) {
          //Redirect to accounts chooser
        } else {
          getParent(self).updateCurrentAccount(self.accounts[0].id);
        }
        getParent(self).setLoading(false);
      } catch (error) {
        alert(
          "Sorry, there was an issue loading your account. Try logging in again..."
        );
        console.log("👤 SIGNING OUT... DUE TO USER LOADING ISSUE");
        getParent(self).hideCart();
        getParent(self).updateCurrentOrderId(null);

        auth.signOut();
        setTimeout(() => window.location.reload(true), 1000);

        getParent(self).setLoading(false);

        console.log(error);
      }
    }),
    updateDisplayName(newDisplayName) {
      self.displayName = newDisplayName;
    },
    updateEmail(newEmail) {
      self.email = newEmail;
    },
    updateAccessToken(newAccessToken) {
      self.accessToken = newAccessToken;
    },
    addAccount(newAccount) {
      self.accounts.push(newAccount);
    }
  }))
  .views(self => ({
    /*get totalDocs() {
      return self.docs.length;
    },*/
    // get isAdmin() {
    //   return self.email === "marcus@dovetalecollections.com";
    // }
  }));
